<template>
  <div class="edit-predicted-transaction">
    <colored-popup :class="variant" :close-action="closeAction">

      <template v-slot:top-content>
        <div class="transaction-title">
          <span class="name">{{ incomeOrExpense }} קבועה</span>
          • <span class="expense">{{ selectedExpenseCategory }}</span>
        </div>
        <transaction-details :transaction="predictedTransaction.actual" />
      </template>

      <template v-slot:bottom-content>
        <div class="question">איזו {{ incomeOrExpense }} קבועה זו?</div>
        <plain-dropdown :options="fixedCategories"
                        :first-selected="selectedExpenseCategory"
                        :on-select="onSelection"
                        :variant="variant"/>
        <text-input v-if="isCustomCategory"
                    class="custom-category"
                    :labelText="'שם הקטגוריה'"
                    :value="customCategoryName"
                    :on-input="onInput" />
        <riseup-button :action="onSave"
                       :size="'slim'"
                       :variant="'primary'"
                       :disabled="isButtonDisabled"
                       :title="'שמירה'" />
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import consts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import transactionUtils from '@/utils/transaction';
import TransactionDetails from '../TransactionDetails.vue';

export default {
  name: 'EditPredictedTransaction',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    PlainDropdown: BaseUI.PlainDropdown,
    RiseupButton: BaseUI.RiseupButton,
    TextInput: BaseUI.TextInput,
    TransactionDetails,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    nextAction: {
      type: Function,
      required: true,
    },
    fixedCategories: {
      type: Array,
      required: true,
    },
    setNewExpenseCategory: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedExpenseCategory: null,
      customCategoryName: '',
    };
  },
  created() {
    Segment.trackUserInteraction('EditPredictedTransaction_Shown', {
      predictedTransaction: this.predictedTransaction,
    });
    this.selectedExpenseCategory = this.predictedTransaction.expense;
  },
  methods: {
    onSelection(expenseCategory) {
      this.selectedExpenseCategory = expenseCategory;
    },
    onSave() {
      Segment.trackUserInteraction('EditPredictedTransaction_SaveClicked', {
        predictedTransaction: this.predictedTransaction,
        selectedExpenseCategory: this.expenseCategoryName,
        isCustomCategory: this.isCustomCategory,
      });
      this.setNewExpenseCategory(this.expenseCategoryName);
      this.nextAction();
    },
    onInput(text) {
      this.customCategoryName = text;
    },
  },
  computed: {
    isIncome() {
      return this.predictedTransaction.isIncome;
    },
    incomeOrExpense() {
      return transactionUtils.incomeOrExpense(this.predictedTransaction);
    },
    variant() {
      return this.isIncome ? 'success' : 'fixed';
    },
    isCustomCategory() {
      return this.selectedExpenseCategory === consts.OTHER_CATEGORY;
    },
    expenseCategoryName() {
      return this.isCustomCategory ? this.customCategoryName : this.selectedExpenseCategory;
    },
    isButtonDisabled() {
      return (!this.isCustomCategory && this.selectedExpenseCategory === this.predictedTransaction.expense)
        || (this.isCustomCategory && this.customCategoryName.length < 2);
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .edit-predicted-transaction {
    text-align: right;

    .colored-top {
      color: $riseup_white;

      .transaction-title {
        margin-bottom: 16px;
        line-height: 11px;

        .name, .dot{
          font-weight: bold;
        }
      }
    }

    .bottom {
      .question {
        color: $riseup_black;
        line-height: 13px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .plain-dropdown {
        margin-bottom: 40px;
      }

      .custom-category {
        margin-bottom: 40px;
      }

      .riseup-button {
        width: 100%;
      }
    }
  }

</style>
