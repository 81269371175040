<template>
  <div class="edit-predicted-with-actual-popup-flow">
    <edit-predicted-transaction v-if="componentIndex === 0"
                                      :predicted-transaction="predictedTransaction"
                                      :close-action="closeAction"
                                      :next-action="next"
                                      :fixed-categories="fixedCategories"
                                      :set-new-expense-category="setNewExpenseCategory" />

    <edit-predicted-transaction-summary v-else
                                      :predicted-transaction="predictedTransaction"
                                      :back-action="back"
                                      :close-action="closeAction"
                                      :old-expense-category="oldExpenseCategory"
                                      :new-expense-category="newExpenseCategory" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Segment from '@/Segment';
import consts from '@/constants/cashflow-view';
import EditPredictedTransaction from '../EditPredictedTransaction.vue';
import EditPredictedTransactionSummary from '../EditPredictedTransactionSummary.vue';

export default {
  name: 'EditPredictedWithActualFlow',
  components: {
    EditPredictedTransaction,
    EditPredictedTransactionSummary,
  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentIndex: 0,
      oldExpenseCategory: this.predictedTransaction.expense,
      newExpenseCategory: null,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['fixedExpenseCategories', 'fixedIncomeCategories']),
    fixedCategories() {
      const categories = this.predictedTransaction.isIncome
        ? [...this.fixedIncomeCategories, ...consts.TRANSFERABLE_FIXED_INCOME_CATEGORIES]
        : [...this.fixedExpenseCategories, ...consts.TRANSFERABLE_FIXED_EXPENSE_CATEGORIES];
      return _.chain(categories)
        .uniq()
        .sortBy()
        .reject(category => category === consts.OTHER_CATEGORY)
        .reject(category => consts.FIXED_SAVING_CATEGORY_NAMES.includes(category))
        .concat(consts.OTHER_CATEGORY)
        .value();
    },
  },
  methods: {
    setNewExpenseCategory(newExpenseCategory) {
      this.newExpenseCategory = newExpenseCategory.trim();
    },
    closeAction() {
      Segment.trackUserInteraction('EditPredictedWithActualFlow_Closed', {
        predictedTransaction: this.predictedTransaction,
      });
      this.$emit('close');
    },
    back() {
      this.componentIndex = 0;
    },
    next() {
      this.componentIndex = 1;
    },
  },
};
</script>
